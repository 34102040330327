import {
  DiagnosticReportResult,
  GeneVariantClassification,
  OrderStatus,
  PatientAppCreatePatientDataInput,
  PatientAppUpdatePatientInput,
  useCreateAssessmentMutation,
  useCreateDiagnosticReportMutation,
  useCreateDocumentMutation,
  useCreateOrderMutation,
  useCreatePatientDataMutation,
  useEvaluateRuleEngineMutation,
  usePublishOutboxEventMutation,
  useUpdatePatientMutation,
} from '__generated__/graphql'
import { StoryPlayerProps } from 'components/StoryPlayer'
import { OrderModule } from 'components/StoryPlayer/Modules/Order'
import {
  CreateAssessmentEvent,
  CreateDiagnosticReportEvent,
  CreateDocumentEvent,
  EvaluateRuleEngineEvent,
} from 'components/StoryPlayer/StoryEvents'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { get, isNil, omitBy } from 'lodash'
import { useContext, useMemo } from 'react'

export default function useStoryEvents() {
  const { refetchPatientData } = useContext(StoryPlayerContext)
  const [createPatientData] = useCreatePatientDataMutation()
  const [createDiagnosticReport] = useCreateDiagnosticReportMutation()
  const [updatePatient] = useUpdatePatientMutation()
  const [evaluateRuleEngine] = useEvaluateRuleEngineMutation()
  const [createDocument] = useCreateDocumentMutation()
  const [publishOutboxEvent] = usePublishOutboxEventMutation()
  const [createOrder] = useCreateOrderMutation()
  const [createAssessment] = useCreateAssessmentMutation()
  const result = useMemo<{
    handleStoryEvent: StoryPlayerProps['onStoryEvent']
  }>(() => {
    return {
      async handleStoryEvent(
        event,
        {
          parseEventValue,
          questionnaireResponses,
          questionnaireResponseItems,
        },
      ) {
        // Create patient data for latest questionnaire responses.
        const handleCreatePatientData = () => {
          // Filter all questionnaire response items including the `patientData.*` name prefix.
          const fieldMetadata =
            questionnaireResponseItems.find(
              (item) => item.name === 'patientData',
            )?.metadata?.fields ?? []
          const patientData = questionnaireResponses.patientData ?? {}
          const patientDataKeys = Object.keys(patientData)
          const createPatientDataInput: PatientAppCreatePatientDataInput[] =
            patientDataKeys.reduce<
              PatientAppCreatePatientDataInput[]
            >((acc, fieldName) => {
              const value = patientData[fieldName]
              const fields = (fieldMetadata ?? []).filter(
                (item) => !!item.name.match(`^${fieldName}`),
              )
              const metadata = { fields }
              const item: PatientAppCreatePatientDataInput = {
                fieldName,
                metadata,
                value: parseEventValue(value),
              }
              acc.push(item)
              return acc
            }, [])

          return createPatientData({
            variables: {
              createPatientDataInput,
            },
          })
        }

        switch (event.type) {
          case 'CreateDiagnosticReport': {
            const { params } = event as CreateDiagnosticReportEvent
            const observations = params.observations ?? []
            await createDiagnosticReport({
              variables: {
                createDiagnosticReportInput: {
                  labId: parseEventValue(params.labId),
                  observations: observations.map((item) => ({
                    classification: parseEventValue(
                      item.classification,
                    ) as GeneVariantClassification,
                    geneId: parseEventValue(item.geneId),
                  })),
                  reportDate: params.reportDate
                    ? parseEventValue(params.reportDate)
                    : undefined,
                  result: parseEventValue(
                    params.result,
                  ) as DiagnosticReportResult,
                },
              },
            })
            break
          }
          case 'CreatePatientData': {
            await handleCreatePatientData()
            break
          }
          case 'CreateAssessmentEvent': {
            const { params } = event as CreateAssessmentEvent
            const { assessmentTemplateId } = params
            // Persist latest patient data.
            await handleCreatePatientData()

            await createAssessment({
              variables: {
                createAssessmentInput: {
                  assessmentTemplateId,
                },
              },
            })
            break
          }
          case 'EvaluateRuleEngine': {
            const { params } = event as EvaluateRuleEngineEvent
            const { ruleEngines } = params

            // Persist latest patient data.
            await handleCreatePatientData()

            // Evaluate configured rule engines.
            await Promise.all(
              ruleEngines.map(({ id }) =>
                evaluateRuleEngine({
                  variables: {
                    runRuleEngineEvaluationInput: {
                      ruleEngineId: id,
                    },
                  },
                }),
              ),
            )

            // Sync latest patient data from rule engine evaluation results.
            await refetchPatientData()
            break
          }
          case 'UpdatePatient': {
            const getFieldValue = <T>(
              key: keyof PatientAppUpdatePatientInput,
            ): T => get(questionnaireResponses, `patient.${key}`) as T
            const updatePatientInput: PatientAppUpdatePatientInput =
              // Only following patient fields are allowed to update.
              omitBy(
                {
                  address: getFieldValue('address'),
                  biologicalSex: getFieldValue('biologicalSex'),
                  birthDate: getFieldValue('birthDate'),
                  email: getFieldValue('email'),
                  ethnicities: getFieldValue('ethnicities'),
                  lang: getFieldValue('lang'),
                  phone: getFieldValue('phone'),
                },
                // Exclude any undefined/null values.
                isNil,
              )

            await updatePatient({
              variables: { updatePatientInput },
            })
            break
          }
          case 'CreateDocument': {
            const { params } = event as CreateDocumentEvent
            const { documentTemplateId } = params
            await createDocument({
              variables: { documentTemplateId },
            })
            break
          }
          case 'PublishOutboxEvent': {
            await publishOutboxEvent({
              variables: {
                input: event.params,
              },
            })
            break
          }
          case 'CreateOrder': {
            const intakeFormData = {
              patientData: questionnaireResponses.patientData ?? {},
            }
            const orderableTestIds = (OrderModule.orderableTestIds ??
              []) as string[]

            if (!orderableTestIds.length) {
              console.warn(
                'No orderable test ids found to create order.',
              )
              return
            }

            await createOrder({
              variables: {
                createOrderInput: {
                  intakeFormData,
                  orderableTestIds,
                  status: OrderStatus.PENDING,
                },
              },
            })
            break
          }
        }
      },
    }
  }, [
    createDocument,
    createDiagnosticReport,
    createPatientData,
    evaluateRuleEngine,
    updatePatient,
    refetchPatientData,
    publishOutboxEvent,
    createOrder,
    createAssessment,
  ])

  return result
}
