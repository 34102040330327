/**
 * Shared types
 * name: nest-client-api
 * version: 1.42.5
 * src: ../../backend/client-api/src/common/shared/utils/TelUtil.ts
 */

/* eslint-disable */

import {
  PhoneNumberFormat,
  PhoneNumberUtil,
  RegionCode,
} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()
export function formatTel(
  e164Number: string,
  region: RegionCode = 'US',
  internationalFormat?: boolean,
) {
  internationalFormat = internationalFormat ?? region !== 'US'
  if (!e164Number) {
    return ''
  }
  try {
    const number = phoneUtil.parse(e164Number, region)
    if (internationalFormat) {
      return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)
    }
    return phoneUtil.formatInOriginalFormat(number)
  } catch (e) {
    console.error(e)
    return ''
  }
}

export function isValidPhoneNumber(
  e164Number: string,
  region: RegionCode = 'US',
): boolean {
  if (!e164Number) {
    return false
  }
  try {
    const number = phoneUtil.parse(e164Number, region)
    return phoneUtil.isValidNumberForRegion(number, region)
  } catch {
    return false
  }
}
